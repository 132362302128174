body {
  background-color: #3aafa9;
  padding: 20px;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
  color: #def2f1;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #2b7a78;
  padding: 0;
}
ul.header li a {
  color: #def2f1;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #2b7a78;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.active {
  background-color: #17252a;
}
#gif {
  display:block;
  margin:auto;
}
#Art {
  max-width: 100%;
  max-height: 100%;
}